@import '../../../../../scss/bootstrap-variables';

$spacing: 20px;
$border: solid 1px $gray-light;

%gray-text {
  color: $text-inactive;
  font-size: 14px;
  font-weight: 500;
}

.tickets-comments-and-contact {
  padding: 40px 0 0 64px;

  .ticket-contact {
    padding-bottom: 28px;

    .contact-item {
      &:not(:last-child) {
        margin-right: 113px;
      }

      .contact-item-header {
        @extend %gray-text;
      }

      .contact-item-data {
        font-size: 14px;
      }
    }
  }

  &.comments-only {
    .ticket-comments .comment:first-child {
      padding: 0 0 $spacing 0;
      border-top: none;
    }
  }

  .ticket-comments {
    padding-top: 12px;
    font-size: 16px;

    .comment-description {
      font-weight: 400;
      font-size: 14px;
    }

    .comment:not(:first-of-type) {
      border-top: $border;
    }

    .comment:not(:first-child).white-border {
      border-top: 1px solid $white;
    }

    .new-comment-container {
      position: relative;

      .comment-send {
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 14px;
      }
    }
  }
}
